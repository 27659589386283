import React, { useState, useEffect, useRef } from "react"
import BookmarkModal from "../../../components/Common/BookmarkModal"
import Pagination from "../../../components/Common/Pagination"
import { platforms, sortingOptions, saveFilterAllowedAccounts } from "../../../constants"
import InfluencerCard from "../../../components/FindInfluencers/Card"
import FiltersContainer from "../../../components/FindInfluencers/FiltersContainer"
import useInfluencerService from "../../../services/useInfluencerService"
import useBoardsService from "../../../services/useBoardsService"
import Skeleton from "@mui/material/Skeleton"
import PlatformSwitch from "../../../components/FindInfluencers/PlatformSwitch"
import { Button } from "../../../components/Common/CommonElements"
import { useSelector } from "react-redux"
import NoFiltersView from "../../../components/FindInfluencers/NoFiltersView"
import EmptyView from "../../../components/FindInfluencers/EmptyView"
import SelectCards from "../../../components/FindInfluencers/SelectCards"
import SearchBar from "../../../components/FindInfluencers/SearchBar"
import SortCards from "../../../components/FindInfluencers/SortCards"
import SelectedFilters from "../../../components/FindInfluencers/SelectedFilters"
import { humanizeNumber, isArrayEmpty, isObjectEmpty } from "../../../helpers"
import { useSearchParams } from "react-router-dom"
import notify from "../../../components/Common/Toast"
import { FormModal } from "../../../components/Common/Modals"
import Playbook from "../../../components/FindInfluencers/Playbook"
import "./styles.scss"

const FindInfluencers = ({ updateBoards, openDetails }) => {
  const influencerService = useInfluencerService()
  const boardsService = useBoardsService()

  const bookmarkModalRef = useRef()
  const bookmarkModal = bookmarkModalRef.current

  const currentUser = useSelector((state) => state.auth?.user)
  const credits = useSelector((state) => state.auth?.credits)

  const [searchParams, setSearchParams] = useSearchParams()
  const queryPlatform = searchParams.get("platform")
  const queryPage = searchParams.get("page")
  const querySort = sortingOptions.find((option) => option.value === (searchParams.get("sort") || "followers"))
  const queryFilters = JSON.parse(searchParams.get("filters"))
  const queryFiltersApplied = useRef(queryFilters)

  const [influencers, setInfluencers] = useState()
  const [currentPlatform, setCurrentPlatform] = useState(queryPlatform || platforms[0])
  const [currentPage, setCurrentPage] = useState(queryPage || 1)
  const [currentSorting, setCurrentSorting] = useState(querySort || sortingOptions[0])
  const [currentFilters, setCurrentFilters] = useState({})
  const [itemsPerPage, setItemsPerPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [totalResults, setTotalResults] = useState()
  const [influencerFilters, setInfluencerFilters] = useState(queryFilters?.influencer || {})
  const [audienceFilters, setAudienceFilters] = useState(queryFilters?.audience || {})
  const [selectedCards, setSelectedCards] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPlaybook, setCurrentPlaybook] = useState()

  const [formModal, setFormModal] = useState({
    isOpen: false,
    isLoading: false,
  })

  const openFormModal = () => {
    setFormModal((prevState) => ({
      ...prevState,
      isOpen: true,
    }))
  }

  const closeFormModal = () => {
    setFormModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }))
  }

  const setFormModalLoading = (value) => {
    setFormModal((prevState) => ({
      ...prevState,
      isLoading: value,
    }))
  }

  const filtersAreEmpty = isObjectEmpty(influencerFilters) && isObjectEmpty(audienceFilters)

  useEffect(() => {
    let urlFilters = null

    if (!isObjectEmpty(queryFilters)) {
      const parsedFilters = parseFilters()
      urlFilters = {
        influencer: parsedFilters.influencer,
        audience: parsedFilters.audience,
      }
    }

    setIsLoading(true)
    influencerService
      .index(currentPlatform, currentPage - 1, urlFilters || currentFilters, currentSorting)
      .then((data) => {
        if (data) {
          setInfluencers(data.influencers)
          setTotalItems(data.meta.pagination.total_items)
          setItemsPerPage(data.meta.pagination.items_per_page)
          setTotalResults(humanizeNumber(data.meta.total_results))
        }
      })
      .finally(() => {
        setIsLoading(false)
        setSearchParams("")
      })
  }, [currentPlatform, currentPage, currentFilters, currentSorting])

  const handleCardClick = (influencer) => {
    if (influencer.saved !== false || credits >= 5) {
      openDetails(influencer.id)
    } else {
      notify.error("Not enough credits to perform this action, please upgrade your plan")
    }
  }

  const onPlatformChange = (platform) => {
    setCurrentPlatform(platform)
    setInfluencerFilters({})
    setAudienceFilters({})
    if (currentPage !== 1) {
      setCurrentPage(1)
    }
    if (!filtersAreEmpty) {
      setCurrentFilters({})
    }
  }

  const onPageChange = (page) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1)
    }
    document.getElementById("cards-container").scrollIntoView({ behavior: "smooth" })
  }

  const submitFilters = () => {
    const parsedFilters = parseFilters()
    setCurrentPage(1)
    setCurrentFilters({
      influencer: parsedFilters.influencer,
      audience: parsedFilters.audience,
    })
    navigator.clipboard.writeText(generateUrl())
  }

  const clearFilters = () => {
    setInfluencerFilters({})
    setAudienceFilters({})
    if (currentPage !== 1) {
      setCurrentPage(1)
    }
    if (!filtersAreEmpty) {
      setCurrentFilters({})
    }
    setCurrentPlaybook()
  }

  const copyAndSaveFilters = ({ name }) => {
    const url = generateUrl()
    navigator.clipboard.writeText(url)

    setFormModalLoading(true)
    influencerService
      .saveFilters(name, url)
      .then((data) => {
        if (data) {
          closeFormModal()
        }
      })
      .finally(() => {
        setFormModalLoading(false)
      })

    return true
  }

  const generateUrl = () => {
    const encodedFilters = encodeURIComponent(
      JSON.stringify({
        influencer: influencerFilters,
        audience: audienceFilters,
      })
    )
    const { protocol, hostname, port } = window.location
    return `${protocol}//${hostname}${port ? `:${port}` : ""}/brand/dashboard?platform=${currentPlatform}&page=${currentPage}&sort=${currentSorting["value"]}&filters=${encodedFilters}`
  }

  const parseFilters = () => {
    const influencer = {}
    const audience = {}

    Object.entries(influencerFilters).forEach(([key, value]) => {
      if (value !== null && value?.length !== 0) {
        switch (key) {
          case "accountTypes":
          case "location":
          case "brands":
          case "interests":
            influencer[key] = value.map((item) => item.value)
            break
          case "age":
          case "followers":
          case "bio":
          case "keywords":
          case "reelsPlays":
          case "views":
          case "engagements":
          case "saves":
          case "shares":
            influencer[key] = value
            break
          case "engagementRate":
          case "gender":
          case "language":
          case "lastposted":
          case "hasSponsoredPosts":
          case "isVerified":
          case "hasYouTube":
            influencer[key] = value.value
            break
          case "followersGrowthRate":
          case "likesGrowthRate":
          case "viewsGrowthRate":
            influencer[key] = { interval: value.value, value: value.weight, operator: value.weight > 0 ? "gt" : "lt" }
            break
          case "hasContactDetails":
            influencer[key] = value.map((item) => ({ contactType: item.value, filterAction: "should" }))
            break
          case "hashtags":
            influencer["textTags"] = (influencer["textTags"] || []).concat(value.map((item) => ({ type: "hashtag", value: item.value.trim() })))
            break
          case "mentions":
            influencer["textTags"] = (influencer["textTags"] || []).concat(value.map((item) => ({ type: "mention", value: item.value })))
            break
          case "topics":
            influencer["relevance"] = (influencer["relevance"] || []).concat(
              value.map((item) => {
                const trimmedValue = item.value.trim()
                return trimmedValue.startsWith("#") ? trimmedValue : `#${trimmedValue}`
              })
            )
            break
          case "lookalikes":
            influencer["relevance"] = (influencer["relevance"] || []).concat(
              value.map((item) => {
                const trimmedValue = item.value.trim()
                return trimmedValue.startsWith("@") ? trimmedValue : `@${trimmedValue}`
              })
            )
            break
          default:
            break
        }
      }
    })

    Object.entries(audienceFilters).forEach(([key, value]) => {
      if (value !== null && value?.length !== 0) {
        switch (key) {
          case "credibility":
            audience[key] = value.value
            break
          case "gender":
          case "language":
            audience[key] = { id: value.value, weight: value?.weight }
            break
          case "interests":
          case "location":
          case "age":
            audience[key] = value.map((item) => ({ id: item.value, weight: item?.weight }))
            break
          default:
            break
        }
      }
    })

    return { influencer, audience }
  }

  const handleCardSelect = (id) => {
    if (!selectedCards?.includes(id)) {
      setSelectedCards((prev) => [...prev, id])
    } else {
      setSelectedCards((prev) => prev.filter((influencer_id) => influencer_id !== id))
    }
  }

  const handleCardBulkSelect = () => {
    if (!influencers?.map((influencer) => influencer.id).every((id) => selectedCards.includes(id))) {
      setSelectedCards(influencers.map((influencer) => influencer.id))
    } else {
      setSelectedCards([])
    }
  }

  const handleAddCardsToBoard = (influencers) => {
    bookmarkModal?.openModal(influencers, addCardsToBoard)
  }

  const addCardsToBoard = (values) => {
    bookmarkModal.setLoading(true)
    boardsService.createCard(values).then((data) => {
      if (data) {
        updateBoards((prev) => !prev)
        setSelectedCards([])
        bookmarkModal.setLoading(false)
        bookmarkModal.closeModal()
      }
    })
  }

  return (
    <div id="find-influencers-section">
      <div className="control-panel-container">
        <div className="actions-container">
          <PlatformSwitch currentPlatform={currentPlatform} onPlatformChange={onPlatformChange} />
          <SearchBar name="lookalikes" service={influencerService.searchInfluencers} platform={currentPlatform} limit={15} state={influencerFilters} setState={setInfluencerFilters} />
        </div>
        <FiltersContainer
          currentPlatform={currentPlatform}
          currentFilters={currentFilters}
          influencerFilters={influencerFilters}
          setInfluencerFilters={setInfluencerFilters}
          audienceFilters={audienceFilters}
          setAudienceFilters={setAudienceFilters}
        />
        <div className="flex-container">
          {isLoading ? (
            <Skeleton variant="rounded" width={150} height={24} />
          ) : totalResults ? (
            <div className="total-results-text">{totalResults} results</div>
          ) : (
            <div className="total-results-text">No results found</div>
          )}
          <div className="flex-container">
            {!filtersAreEmpty && saveFilterAllowedAccounts.includes(currentUser?.email) && (
              <Button
                type="button"
                label="Copy & Save Filters"
                onClick={openFormModal}
                theme={{ backgroundColor: "#ffffff", borderColor: "#000000", color: "black", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              />
            )}
            {!filtersAreEmpty && (
              <Button
                type="button"
                label="Clear All"
                onClick={clearFilters}
                theme={{ backgroundColor: "#ffffff", borderColor: "#000000", color: "black", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              />
            )}
            <Button
              type="button"
              label="Apply Filters"
              onClick={submitFilters}
              theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              isDisabled={filtersAreEmpty}
            />
          </div>
        </div>
      </div>
      <div id="cards-container"></div>
      <SelectedFilters
        isLoading={isLoading}
        influencerFilters={influencerFilters}
        setInfluencerFilters={setInfluencerFilters}
        audienceFilters={audienceFilters}
        setAudienceFilters={setAudienceFilters}
        currentPlaybook={currentPlaybook}
      />
      <div className="actions-flex-container">
        <SelectCards influencers={influencers} isLoading={isLoading} selectedCards={selectedCards} handleCardBulkSelect={handleCardBulkSelect} handleAddCardsToBoard={handleAddCardsToBoard} />
        <SortCards isLoading={isLoading} state={currentSorting} setState={setCurrentSorting} cardsPresent={!isArrayEmpty(influencers)} filtersApplied={!isObjectEmpty(currentFilters)} />
      </div>
      <div className="cards-container">
        {isLoading
          ? Array.from({ length: 15 }, (_, index) => <Skeleton key={index} className="card-skeleton" variant="rounded" height={277} />)
          : influencers?.map((influencer, index) => (
              <InfluencerCard
                key={influencer.id}
                influencer={influencer}
                handleAddCardsToBoard={handleAddCardsToBoard}
                handleCardSelect={handleCardSelect}
                handleCardClick={handleCardClick}
                selectedCards={selectedCards}
                setSelectedCards={setSelectedCards}
                lockFeatures={currentUser?.lock_features && index !== 0}
              />
            ))}
      </div>
      {!isLoading &&
        influencers?.length > 0 &&
        (queryFiltersApplied.current || !isObjectEmpty(currentFilters) ? (
          <Pagination activePage={currentPage} totalItems={totalItems} itemsPerPage={itemsPerPage} onPageChange={onPageChange} lockFeatures={currentUser?.lock_features} />
        ) : (
          <NoFiltersView />
        ))}
      {!isLoading && influencers?.length === 0 && <EmptyView />}
      <BookmarkModal ref={bookmarkModalRef} />
      <FormModal isOpen={formModal.isOpen} closeModal={closeFormModal} isLoading={formModal.isLoading} heading="Enter a name for your filters" callback={copyAndSaveFilters} />
      <Playbook
        username={currentUser?.name}
        setCurrentPlatform={setCurrentPlatform}
        influencerService={influencerService}
        influencerFilters={influencerFilters}
        setInfluencerFilters={setInfluencerFilters}
        audienceFilters={audienceFilters}
        setAudienceFilters={setAudienceFilters}
        setCurrentPlaybook={setCurrentPlaybook}
        submitFilters={submitFilters}
        clearFilters={clearFilters}
      />
    </div>
  )
}

export default FindInfluencers
