import React from "react"
import { useNavigate } from "react-router-dom"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import useAuthService from "../../../services/useAuthService"
import { Button } from "../../Common/CommonElements"
import FacebookIcon from "../../../assets/images/facebook.svg"

const FacebookAuth = () => {
  const navigate = useNavigate()
  const authService = useAuthService()

  const callback = async (res) => {
    const params = { token: res.accessToken, provider: "facebook" }
    authService.socialAuthentication(params).then((data) => {
      if (data && !data.role) {
        navigate("/set_role")
      } else if (data && data.role) {
        navigate(`/${data.role}/dashboard`)
      }
    })
  }

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name, email"
      callback={callback}
      render={(renderProps) => (
        <Button
          type="button"
          icon={<img src={FacebookIcon} alt="" />}
          onClick={renderProps.onClick}
          theme={{ backgroundColor: "#f6f7fb", color: "#000000", padding: "0.8rem 2.2rem", fontSize: "12px", width: "240px" }}
        />
      )}
    />
  )
}

export default FacebookAuth
