import React, { useState, useEffect, useRef } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import useAuthService from "../../../services/useAuthService"
import { TextInput, PasswordInput, CheckboxInput, Button, LoadingButton } from "../../../components/Common/CommonElements"
import GoogleAuth from "../../../components/Authentication/Google"
import FacebookAuth from "../../../components/Authentication/Facebook"
import ReCAPTCHA from "react-google-recaptcha"
import notify from "../../../components/Common/Toast"
import "./styles.scss"
import Logo from "../../../assets/images/logo.svg"
import InfluencerCards from "../../../assets/images/influencers-cards.svg"
import { influencerTypeWithLocation } from "../../../constants"
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"

const Register = () => {
  const authService = useAuthService()
  const [showForm, setShowForm] = useState(true)
  const [recaptchaToken, setRecaptchaToken] = useState()
  const [loading, setLoading] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [index, setIndex] = useState(0)
  const [referral, setReferral] = useState(null)
  const [user, setUser] = useState()

  const recaptchaRef = useRef(null)

  const { isLoading, error, data, getData } = useVisitorData({ extendedResult: true })

  useEffect(() => {
    if (user && !isLoading && !error && data) {
      authService.addFingerprint(user?.id, data)
    }
  }, [user, isLoading])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % influencerTypeWithLocation.length)
    }, 3000)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    const wordSpans = document.querySelectorAll(".special_word")
    wordSpans?.forEach((wordSpan) => {
      wordSpan.classList.remove("show")
      setTimeout(() => {
        wordSpan.classList.add("show")
      }, 2000)
    })
  }, [index])

  useEffect(() => {
    window.rewardful("ready", () => {
      setReferral(window.Rewardful.referral)
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "brand",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      companyName: Yup.string(),
      email: Yup.string().email("Email is invalid").required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|[\W_]).+$/, "Password must contain upper and lower case characters, and at least one number or symbol"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      if (!agreeToTerms) {
        notify.error("You must agree with Hiveinfluence's Terms & Conditions and Privacy Policy")
        return
      }

      if (!recaptchaToken) {
        notify.error("Please verify the ReCAPTCHA")
        return
      }

      setLoading(true)
      authService
        .register(values, recaptchaToken, referral)
        .then((data) => {
          if (data && !data.confirmed) {
            getData()
            setShowForm(false)
            setUser(data)
          } else {
            recaptchaRef.current.reset()
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  const { values, touched, errors } = formik

  const handleResendConfirmation = async () => {
    authService.resendConfirmation(values.email)
  }

  const handleSwitchRole = (role) => {
    formik.setFieldValue("role", role)
  }

  const handleRecaptcha = (value) => {
    setRecaptchaToken(value)
  }

  return (
    <div className="register-page">
      <div className="row">
        <div className="col-5 d-lg-block d-none">
          <div className="background-container">
            <div className="heading-text">
              Find <span className="special_word">{influencerTypeWithLocation[index].type}</span> influencers <br /> in{" "}
              <span className="special_word">{influencerTypeWithLocation[index].location}</span>
            </div>
            <div className="image-container">
              <img src={InfluencerCards} className="image" alt="" />
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-12">
          <div className="form-container">
            {showForm ? (
              <form onSubmit={formik.handleSubmit} className="form">
                <div className="logo-container" onClick={() => (window.location.href = process.env.REACT_APP_LANDING_PAGE_URL)}>
                  <img src={Logo} className="logo" alt="logo" />
                </div>
                <div className="role-outer-container">
                  <div className="role-inner-container">
                    <Button
                      type="button"
                      label="I AM A BRAND"
                      theme={{
                        backgroundColor: values.role === "brand" ? "#94a4ff" : "#f5f6fa",
                        color: values.role === "brand" ? "#000000" : "#555f61",
                        padding: "0.5rem 0.8rem",
                        fontSize: "14px",
                        width: "50%",
                      }}
                      onClick={() => handleSwitchRole("brand")}
                    />
                    <Button
                      type="button"
                      label="I AM A INFLUENCER"
                      theme={{
                        backgroundColor: values.role === "influencer" ? "#94a4ff" : "#f5f6fa",
                        color: values.role === "influencer" ? "#000000" : "#555f61",
                        padding: "0.5rem 0.8rem",
                        fontSize: "14px",
                        width: "50%",
                      }}
                      onClick={() => (window.location.href = `${process.env.REACT_APP_LANDING_PAGE_URL}/creator`)}
                    />
                  </div>
                </div>
                <div className="form-fields-container">
                  <div className="form-field-container">
                    <TextInput label="Name" name="name" value={values.name} onChange={formik.handleChange("name")} error={touched.name && errors.name} />
                  </div>
                  <div className="form-field-container">
                    <TextInput label="Company Name" name="companyName" value={values.companyName} onChange={formik.handleChange("companyName")} error={touched.companyName && errors.companyName} />
                  </div>
                  <div className="form-field-container">
                    <TextInput label="Email" name="email" value={values.email} onChange={formik.handleChange("email")} error={touched.email && errors.email} />
                  </div>
                  <div className="form-field-container">
                    <PasswordInput label="Password" name="password" value={values.password} onChange={formik.handleChange("password")} error={touched.password && errors.password} />
                  </div>
                  <div className="form-field-container">
                    <PasswordInput
                      label="Confirm Password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={formik.handleChange("confirmPassword")}
                      error={touched.confirmPassword && errors.confirmPassword}
                    />
                  </div>
                </div>
                <div className="terms-container">
                  <CheckboxInput
                    label={
                      <>
                        <Link to={`${process.env.REACT_APP_LANDING_PAGE_URL}/terms_condition`} className="link" target="_blank" rel="noopener noreferrer">
                          Terms & Conditions
                        </Link>{" "}
                        and{" "}
                        <Link to={`${process.env.REACT_APP_LANDING_PAGE_URL}/privacy_policy `} className="link" target="_blank" rel="noopener noreferrer">
                          Privacy Policy
                        </Link>
                      </>
                    }
                    name="agreeToTerms"
                    value={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                    color="#000000"
                  />
                </div>
                <div className="recaptcha-container">
                  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} ref={recaptchaRef} />
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <LoadingButton
                    type="submit"
                    label="Register"
                    theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px", width: "100%" }}
                    isLoading={loading}
                  />
                </div>
                <div className="social-buttons-container">
                  <GoogleAuth />
                  <FacebookAuth />
                </div>
                <div className="text-center mb-5">
                  <div>
                    Already have an account ?{" "}
                    <Link to="/login" className="link">
                      Log in
                    </Link>
                  </div>
                </div>
              </form>
            ) : (
              <div className="form mt-5">
                <div className="logo-container">
                  <img src={Logo} className="logo" alt="" />
                </div>
                <div className="text-container">
                  <div className="primary-text">Account Verification</div>
                  <div className="secondary-text">
                    We have sent you a verfication link at your email <br /> <strong>{formik.values.email}</strong>
                    <br />
                    Didn't get them ?{" "}
                    <span className="link" onClick={handleResendConfirmation}>
                      Resend link
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
